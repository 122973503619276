<script setup>
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import ModalUpdateReview from '@/Pages/Staff/Reviews/Partials/ModalUpdateReview.vue';
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const route = inject('route');
const props = defineProps({
    query: Object,
    reviews: Object,
    locationOptions: Object,
    flexerOptions: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    orderBy: props.query.orderBy ?? 'date',
    orderDir: props.query.orderDir ?? 'asc',
    flexer_id: props.query.flexer_id ?? '',
    location_id: props.query.location_id ?? '',
    page: props.query.page ?? 1,
});

let showEditReviewModel = ref(false);

let letSelectedReview = ref();

const cellClicked = ({ row, column, id }) => {
    if (column != tableSettings.length - 1) {
        return;
    }

    letSelectedReview.value = props.reviews.data.find((review) => review.id == id);
    openShowEditReviewModel();
};

const openShowEditReviewModel = () => {
    showEditReviewModel.value = true;
};

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.reviews.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['reviews', 'query'],
    });
};

const typeFilters = {
    flexer: 'Flexer',
    location: 'Company',
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
    {
        label: t('Date'),
        orderBy: 'date',
        width: '12%',
    },
    {
        label: t('Time {model}', { model: 'shift' }),
        width: '12%',
    },
    { label: t('Position'), width: '15%' },
    { label: t('Type'), orderBy: 'type', width: '8%' },
    { label: t('Receiver'), width: '10%' },
    { label: t('Reviewer'), width: '10%' },
    { label: t('Rating'), orderBy: 'rating', width: '8%', cell: 'Rating' },
    { label: '', width: '5%', cell: 'FakeArrow' },
];

const tableData = computed(() =>
    props.reviews.data.map((review) => {
        let subjectType = 'Unknown';

        if (review.subject_type.includes('Location')) {
            subjectType = t('Location');
        } else if (review.subject_type.includes('User')) {
            subjectType = t('Fl@xr');
        }

        return {
            id: review.id,
            rowData: [
                review.date,
                review.detail,
                review.position,
                subjectType,
                review.subject_name,
                review.reviewer_name,
                [review.rating],
                review.id,
            ],
        };
    }),
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout :title="$t('Given {model}', { model: $t('reviews') })">
        <Filters
            :flexer-options="flexerOptions"
            :form="form"
            :h1="$t(`Reviews`)"
            :has-search="true"
            :location-options="locationOptions"
            :type-filters="typeFilters"
            @anyUpdate="getData()"
            @searchUpdate="getData()"
        />

        <template v-if="typeof reviews !== 'undefined' && reviews.data.length > 0">
            <Table
                v-model:orderBy="form.orderBy"
                v-model:orderDir="form.orderDir"
                :data="tableData"
                :loading="form.processing"
                :table-settings="tableSettings"
                class="mb-8"
                link-opens-popup
                @cellClicked="cellClicked"
                @update:orderBy="getData()"
                @update:orderDir="getData()"
            />

            <Pagination
                v-if="typeof reviews !== 'undefined'"
                :hide-total="query.search != '' && query.search != null"
                :links="reviews.links"
                :page-data="reviews"
                @setPage="setPage"
            />
        </template>
        <template v-else>
            <div class="w-full overflow-hidden rounded bg-white px-4 py-3 font-bold leading-tight">
                {{ $t('No {model} found', { model: $t('reviews') }) }}
            </div>
        </template>
    </StaffLayout>
    <ModalUpdateReview
        v-model:showing="showEditReviewModel"
        :hide-next-button="true"
        :review="letSelectedReview"
    />
</template>
